.demo-logo {
  width: 120px;
  min-width: 120px;
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  margin-inline-end: 24px;
}

.demo-logo-vertical {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
}

html {
  scrollbar-width: thin;
  scrollbar-color: unset;
}
