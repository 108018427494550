[data-prefers-color="dark"] .token.doctype,
[data-prefers-color="dark"] .token.punctuation,
[data-prefers-color="dark"] .token.entity,
[data-prefers-color="dark"] code[class*='language-'],
[data-prefers-color="dark"] pre[class*='language-'] {
  color: #8590a0;
}
[data-prefers-color="dark"] .token.comment,
[data-prefers-color="dark"] .token.prolog,
[data-prefers-color="dark"] .token.cdata {
  color: #616d7f;
}
.dumi-default-source-code {
  position: relative;
  background-color: #fbfcfd;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.dumi-default-source-code-scroll-container {
  overflow: auto;
  width: 100%;
  height: 100%;
}
.dumi-default-source-code-scroll-content {
  position: relative;
  width: max-content;
  height: max-content;
  min-width: 100%;
  min-height: 100%;
}
.dumi-default-source-code-scroll-content > pre.prism-code {
  width: max-content;
  position: relative;
  overflow: visible;
}
.dumi-default-source-code > pre.prism-code {
  overflow: auto;
}
.dumi-default-source-code > pre.prism-code,
.dumi-default-source-code-scroll-content > pre.prism-code {
  margin: 0;
  padding: 18px 24px;
  font-size: 14px;
  line-height: 1.58;
  direction: ltr;
  background: transparent;
}
.dumi-default-source-code > pre.prism-code::before,
.dumi-default-source-code-scroll-content > pre.prism-code::before,
.dumi-default-source-code > pre.prism-code::after,
.dumi-default-source-code-scroll-content > pre.prism-code::after {
  content: none;
}
.dumi-default-source-code > pre.prism-code > .highlighted,
.dumi-default-source-code-scroll-content > pre.prism-code > .highlighted {
  position: relative;
  width: calc(100% + 24px);
  background-color: #eeeff0;
}
.dumi-default-source-code > pre.prism-code > .highlighted .line-cell,
.dumi-default-source-code-scroll-content > pre.prism-code > .highlighted .line-cell {
  position: relative;
}
.dumi-default-source-code > pre.prism-code > .highlighted .line-cell::after,
.dumi-default-source-code-scroll-content > pre.prism-code > .highlighted .line-cell::after {
  content: '';
  position: absolute;
  top: 0;
  right: -24px;
  bottom: 0;
  width: 24px;
  background-color: #eeeff0;
}
.dumi-default-source-code > pre.prism-code > .highlighted::after,
.dumi-default-source-code-scroll-content > pre.prism-code > .highlighted::after {
  content: '';
  position: absolute;
  top: 0;
  left: -24px;
  bottom: 0;
  width: 24px;
  background-color: #eeeff0;
}
.dumi-default-source-code > pre.prism-code > .wrap,
.dumi-default-source-code-scroll-content > pre.prism-code > .wrap {
  display: table-row;
}
.dumi-default-source-code > pre.prism-code > .wrap > .token-line-num,
.dumi-default-source-code-scroll-content > pre.prism-code > .wrap > .token-line-num {
  display: table-cell;
  text-align: right;
  padding-right: 1em;
  user-select: none;
  opacity: 0.5;
}
.dumi-default-source-code > pre.prism-code > .wrap > .line-cell,
.dumi-default-source-code-scroll-content > pre.prism-code > .wrap > .line-cell {
  display: table-cell;
  width: 100%;
}
.dumi-default-source-code-copy {
  position: absolute;
  z-index: 2;
  top: 9px;
  right: 12px;
  display: inline-block;
  padding: 8px 12px;
  background-color: rgba(251, 252, 253, 0.8);
  border: 0;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.2s;
}
.dumi-default-source-code-copy > svg {
  width: 16px;
  fill: #98a3aa;
  transition: fill 0.2s;
}
[data-prefers-color="dark"] .dumi-default-source-code-copy > svg {
  fill: #4a545a;
}
.dumi-default-source-code-copy:hover > svg {
  fill: #7c8a93;
}
[data-prefers-color="dark"] .dumi-default-source-code-copy:hover > svg {
  fill: #616e75;
}
.dumi-default-source-code-copy[data-copied] > svg {
  fill: #208a41;
}
[data-prefers-color="dark"] .dumi-default-source-code-copy[data-copied] > svg {
  fill: #124c24;
}
.dumi-default-source-code:not(:hover) .dumi-default-source-code-copy {
  opacity: 0;
  visibility: hidden;
}
.dumi-default-source-code-language {
  position: absolute;
  top: 2px;
  right: 8px;
  z-index: 2;
  font-size: 12px;
  font-weight: 500;
  user-select: none;
  color: #8a9099;
  transition: color 0.4s, opacity 0.4s;
}
.dumi-default-source-code:hover .dumi-default-source-code-language {
  opacity: 0;
  visibility: hidden;
}
[data-prefers-color="dark"] .dumi-default-source-code {
  background-color: #020305;
}
[data-prefers-color="dark"] .dumi-default-source-code-copy {
  background-color: rgba(2, 3, 5, 0.2);
}
[data-prefers-color="dark"] .dumi-default-source-code > pre.prism-code > .highlighted {
  background-color: #1c1d1e;
}
[data-prefers-color="dark"] .dumi-default-source-code > pre.prism-code > .highlighted::after {
  background-color: #1c1d1e;
}
[data-prefers-color="dark"] .dumi-default-source-code > pre.prism-code > .highlighted .line-cell::after {
  background-color: #1c1d1e;
}
