.dumi-default-source-code-editor {
  position: relative;
}
.dumi-default-source-code-editor-textarea {
  position: absolute;
  z-index: 1;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: transparent;
  caret-color: #30363f;
  overflow-wrap: normal;
  white-space: pre;
  box-sizing: border-box;
  background: transparent;
  opacity: 1;
  border: 0;
  resize: none;
  outline: none;
  overflow: hidden;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
[data-prefers-color="dark"] .dumi-default-source-code-editor-textarea {
  caret-color: #c6c9cd;
}
[data-prefers-color="dark"] .dumi-default-source-code-editor-textarea:focus::selection {
  background-color: rgba(0, 83, 200, 0.3);
}
.dumi-default-source-code-editor::after {
  content: '';
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  pointer-events: none;
}
.dumi-default-source-code-editor:focus-within::after {
  box-shadow: 0 0 0 1px #1677ff inset;
}
[data-prefers-color="dark"] .dumi-default-source-code-editor:focus-within::after {
  box-shadow: 0 0 0 1px #0053c8 inset;
}
